import React, { useEffect, useState, useMemo, useRef } from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import { graphql } from 'gatsby'
import MiniSearch from 'minisearch'
import AggressiveTokenizerFr from 'natural/lib/natural/tokenizers/aggressive_tokenizer_fr'
import { PorterStemmerFr } from 'natural/lib/natural/stemmers'

// markup
const Faq = ({ data }) => {
  const searchInput = useRef(null)

  // Get FAQ data from the GraphQL query result
  const faq = data.allPrismicFaq.edges

  // state for the search string
  const [search, setSearch] = useState('')

  // state for the filtered FAQ items
  const [filteredFaq, setFilteredFaq] = useState(faq)

  // Initialize MiniSearch instance
  const miniSearch = useMemo(() => {
    return new MiniSearch({
      // Fields to search in
      fields: ['answer.text', 'question.text'],
      // Fields to store with search results
      storeFields: ['answer', 'question'],
      // tokenize the search string using a French tokenizer
      tokenize: (string) => new AggressiveTokenizerFr().tokenize(string),
      // stem the search terms using a French stemmer and remove accents
      processTerm: (term, _fieldName) => PorterStemmerFr.stem(term).normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      // extract the field value using a dot notation string
      extractField: (document, fieldName) => {
        return fieldName.split('.').reduce((doc, key) => {
          return doc && doc[key]
        }, document)
      }
    })
  }, [])

  // create an array of FAQ items with unique IDs for MiniSearch
  const faqWithIds = useMemo(() => {
    return faq.flatMap((item, itemIndex) => {
      const { group } = item.node?.data
      if (group) {
        return group.map((obj, index) => ({ id: `${itemIndex}_${index}`, ...obj }))
      }
      return []
    })
  }, [faq])

  // add the FAQ items to MiniSearch when the faqWithIds changes
  useEffect(() => {
    miniSearch.addAll(faqWithIds)
  }, [faqWithIds, miniSearch])

  // filter the FAQ items based on the search string
  useEffect(() => {
    if (search.length > 0) {
      // Perform search with MiniSearch
      const results = miniSearch.search(search, { prefix: true })
      // filter the original faq array to only include matched FAQ items
      const filterFaq = faq
        .map((item, itemIndex) => {
          const { group } = item.node?.data
          // Filter FAQ groups based on search results
          const filteredGroup = group?.filter((obj, index) => results.findIndex((result) => result.id === `${itemIndex}_${index}`) !== -1)
          // Update FAQ item with filtered group
          return { ...item, node: { ...item.node, data: { ...item.node?.data, group: filteredGroup } } }
        })
      // Filter out FAQ items with no matching groups
        .filter((item) => item.node?.data?.group?.length > 0)
      // Sort FAQ items by highest-scoring group
        .sort((a, b) => b.node?.data?.group[0].score - a.node?.data?.group[0].score)
      // Set filtered FAQ state
      setFilteredFaq(filterFaq)
    } else {
      // Set filtered FAQ state to original FAQ data
      setFilteredFaq(faq)
    }
  }, [search, faq, miniSearch])

  const metaTitle = 'Foire aux questions | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'Votre question demeure sans réponse ? Voyez celles que se posent le plus fréquemment nos festivaliers dans notre foire aux questions (FAQ)!'
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, FAQ, questions'

  return (
      <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Faq"
        htmlContent={
          <div>
            <form className="text-right md:mx-[60px] mx-[10px]" name="valform" action="" method="POST" onSubmit={(e) => {
              e.preventDefault()
              setSearch(searchInput.current.value)
            }}>
                <div className="relative md:w-1/2 xl:w-1/3 mx-auto mt-[34px] inline-block w-full">
                  <input ref={searchInput} type="text"
                          name="search"
                          placeholder=""
                          onChange={(e) => setSearch(e.target.value)}
                          className="search-input"/>
                  <div className="absolute inset-y-0 right-0 flex items-center px-3">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                            strokeLinejoin="round">
                          <circle cx="11" cy="11" r="8"/>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"/>
                      </svg>
                  </div>
              </div>
          </form>
        </div>
        }
      />
      <div className="container content pb-12">
        <div>
          <h2>Renseignements</h2>
          <p>Voici une liste des questions que se posent fréquemment nos festivalier.ère.s. Si ta question n’y figure pas, n’hésite pas à nous contacter : <a href="mailto:info@lefestif.ca">info@lefestif.ca</a> ou <a href="tel:+14184360331">(418) 436-0331</a></p>
          <br/>
          <h5>Tu as une question pendant le festival?</h5>
          <p>Rendez-vous au kiosque d’info du festival, situé près du Parvis du Festif! dans le parc situé à côté du Carrefour culturel Paul Médéric du 17 au 21 juillet 2024.</p>
          <br/>
          {/* <h5>Horaires d'ouverture</h5>
          <ul>
            <li>Mercredi 19 juillet : 17h à 21h</li>
            <li>Jeudi 20 juillet : 12h à 21h</li>
            <li>Vendredi 21 juillet : 10h à 21h</li>
            <li>Samedi 22 juillet : 10h à 21h</li>
            <li>Dimanche 23 juillet : 10h à 20h</li>
          </ul> */}
        </div>

        { filteredFaq && filteredFaq.length > 0
          ? filteredFaq.map(({ node }) => { return <FaqItem key={node.id} node={node} /> })
          : <div className="py-12 text-white" > Aucun résultat disponible...
            </div>}
      </div>
    </Wolflayout>
  )
}

const FaqItem = ({ node }) => {
  return (
    <div className="faq">
      <h2 className='!mt-5 !mb-0'>
        {node.data.section_title.text}
      </h2>
        {node.data.group.map((group, i) => {
          return (
              <div key={i}>
                {/* <div key={`${node.id}-${i}`} className="pb-4 content font-poppins text-white font-semibold ml-12 !pl-0"> */}
                  {/* <Collapsible trigger={group.question.text}> */}
                    <h5 className="!font-poppins !font-bold pt-4">{group.question.text}</h5>
                    {/* <p className="ml-8" dangerouslySetInnerHTML={{__html: group.answer.html}}></p> */}
                    <p className="!font-openSans ml-0 !pl-0">{React.createElement('div', { dangerouslySetInnerHTML: { __html: group.answer.html } })}</p>

                  {/* </Collapsible> */}
                </div>
          )
        })}
    </div>
  )
}

export default Faq

export const query = graphql`
  query {
    allPrismicFaq(sort: { fields: data___sort }) {
      edges {
        node {
          data {
            group {
              answer {
                html
                text
              }
              question {
                text
              }
            }
            section_title {
              text
            }
            sort
          }
          id
        }
      }
    }
  }
`
